@import './base';

.login-wrapper {
  align-items: center;
  display: flex;
  min-height: 100vh;

  .login {
    flex: 0 1 fit-content;
    margin: 0 auto;

    .login-links {
      margin-top: 20px;
    }
  }
}
